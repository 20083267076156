import React from "react";
import Layout from "../../components/Layout";
import Seo from "../../components/Seo";
import loadable from "@loadable/component";
import LazyLoadSection from "../../components/LazyLoadSection";
import StaticSection from "../../components/sections/PublishersStackMediaStatic";

const AsyncSection = loadable(() =>
  import("../../components/sections/PublishersStackMediaDefer")
);

const StackMedia = () => (
  <Layout>
    <Seo
      title="Native Advertising Solutions for Publishers"
      description="StackMedia is a performance-based advertising solution to help you grow your revenue through sponsored content, ads, and more."
    />

    <LazyLoadSection static={<StaticSection />}>
      <AsyncSection />
    </LazyLoadSection>
  </Layout>
);

export default StackMedia;
