import React from "react";
import HomeLanding from "../HeroLanding";
import { graphql, Link, useStaticQuery } from "gatsby";
import { brandColor2, gray4 } from "../styles/Colors";
import OutlinedText from "../styles/OutlinedText";
import { Headline80 } from "../styles/Headings";
import HeroTitleOutline from "../HeroOutlineTitle";
import HeroDivideRight from "../HeroDivideRight";
import { SlideInBottom } from "../styles/animations";

const PublishersStackMediaStatic = () => {
  const images = useStaticQuery(graphql`
    query {
      landingDesktop: file(relativePath: { eq: "27-stackads-desktop.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      landingMedia: file(relativePath: { eq: "27-stackads-mobile.png" }) {
        childImageSharp {
          fluid(maxWidth: 911, maxHeight: 1012, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);

  return (
    <>
      <HomeLanding
        section="Stackmedia For Publishers"
        image={images.landingDesktop}
        link={{
          label: "Get Started",
          attrs: { to: "/contact/?smopt=publisher", state: { from: "publisher" }, as: Link }
        }}
        imageMobile={images.landingMedia}
        bottomMargins="-23%"
        blurImage={true}
        theme={{
          keyFrames: SlideInBottom,
          mediaTransform: "translateX(-12px)",
          imageRatio: "110%",
          imageWidth: "140%",
          imageMaxWidth: "140vw",
          topPadding: "98px",
          bottomPadding: "240px",
          imageTranslate:
            "translate(calc(100% * 4.2 / 12), calc(100% * 2.8 / 12))",
          imageMarginTop: 0,
          bottom: gray4,
          mediaImgMargins: "0 auto",
          bottomSize: "calc(100% * 1.8 / 12)",
          mediaImgWidth: "90%",
          bottomSizeBottomMedia: "124px",
          gatsbyWidth: "880px"
        }}
      >
        ADVERTISING <br /> FOR THE
        <OutlinedText component={Headline80} as="span">
          MODERN PUBLISHER
        </OutlinedText>
      </HomeLanding>

      <HeroTitleOutline
        theme={{ background: gray4, fill: gray4, width: "460px" }}
        title="Make The Most Of"
        subtitle="Every Click"
      />

      <HeroDivideRight
        theme={{ background: gray4, lines: brandColor2 }}
        contentTag={"div"}
      >
        <p>
          We connect you with top advertisers to become an extension of your ad
          sales team. That way, you can effortlessly scale ad revenues without
          compromising the reader experience.
        </p>
      </HeroDivideRight>
    </>
  );
};

export default PublishersStackMediaStatic;
